.hero-b2b-container {

  @include media-breakpoint-up(lg) {
    &.hero-b2b-container-c {
      height: 70vh;
      min-height: 538px;
      max-height: 680px;

      .form-panel {
        max-width: 470px;
      }
    }

    .container {
      height: calc(100% - 144px);
    }
  }

  .hero-image-c {
    object-fit: cover;
    max-height: 280px;
    @include media-breakpoint-up(sm) {
      max-height: 360px;
    }
    @include media-breakpoint-up(md) {
      max-height: 100%;
    }
  }

  &.hero-b2b-container-c {
    .hero-b2b-quote-panel {
      right: 0;
      bottom: -55px;
      @include media-breakpoint-up(sm) {
        bottom: 0;
      }
    }
  }

  position: relative;
  z-index: $zindex-hero-b2b-container;
  width: 100%;
  background-color: $hero-b2b-container-background-color;

  .hero-b2b-image {
    position: absolute;
    top: $hero-b2b-image-top;
    width: 100%;

    @include media-breakpoint-up(lg) {
      top: auto;
      bottom: 0;
    }

    img {
      display: block;
      height: $hero-b2b-image-height;
      margin-right: auto;
      margin-left: auto;

      @include media-breakpoint-up(lg) {
        height: $hero-b2b-image-height-lg;
      }
    }
  }

  .hero-b2b-form-panel {
    margin-top: $hero-b2b-form-panel-margin-top;
    @include media-breakpoint-up(lg) {
      margin-top: $hero-b2b-form-panel-margin-top-lg;
    }
  }

  .hero-b2b-form-panel-text {
    @include media-breakpoint-up(lg) {
      width: 370px;
    }
  }

  .hero-b2b-subheader {
    u {
      text-decoration-thickness: 3px;
      text-decoration-color: $accent;
      text-underline-offset: 3px;
      font-weight: 700;
      white-space: nowrap;
    }
  }
}

.hero-b2b {
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-top: $hero-b2b-padding-top;
  }

  .hero-b2b-catch-phrase {
    padding-top: $hero-b2b-catch-phrase-padding-top;
    @include media-breakpoint-up(lg) {
      padding-top: $hero-b2b-catch-phrase-padding-top-lg;
    }
  }
}

.hero-b2b-quote-panel {
  @include media-breakpoint-up(sm) {
    max-width: 354px;
  }
}

.hero-container {
  @extend .container;
  position: relative;
  min-height: 552px;
  padding-top: #{$navbar-height + 32px};
  padding-bottom: 4rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    height: 666px;
    padding-top: 11rem;
  }
}

.hero-image {
  position: absolute;
  top: 201px;
  right: -30px;
  max-height: $hero-height-mobile;
  @include media-breakpoint-up(lg) {
    top: 0;
    right: $hero-position-right-desktop;
    max-height: $hero-height-desktop;
  }
}

.postcode-hero-image {
  display: flex;
  position: relative;
  height: $postcode-hero-height;
  min-height: 400px;
  max-height: 568px;
  margin-right: $postcode-hero-image-margin-x;
  margin-left: $postcode-hero-image-margin-x;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(to right, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0));
  }
}

.postcode-hero-container {
  position: absolute;
  margin-right: $postcode-hero-margin-x;
  margin-left: $postcode-hero-margin-x;
  height: 340px;
  align-self: center;
  text-align: center;

  .postcode-hero-title {
    @extend .h1;
    padding-bottom: 1rem;
    margin-bottom: 0;
    font-size: 2rem;
    color: $white;
    text-shadow: rgba(0, 0, 0, .25) 0 2px 8px;

    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
  }

  .postcode-hero-subtitle {
    font-size: 1rem;
    font-family: $font-family-sofia-pro;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  input,
  button {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    height: auto;

    input {
      width: $postcode-hero-input-width-desktop;
    }

    button {
      width: $postcode-hero-btn-width-desktop;
    }
  }

  .postcode-hero-form-container {

    @include media-breakpoint-up(md) {
      margin: auto;
      max-width: $postcode-hero-form-width-desktop;
    }

    .postcode-hero-panel {
      @extend .panel-bordered;
      @extend .panel-small;
    }

    .postcode-hero-text {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;

      .no-obligation {
        @extend .p-small;
        margin: 0;
        font-weight: bold;
        color: $white;
        line-height: 1;
      }
    }
  }
}

.company-logo-container-wrapper {
  max-width: 270px;

  .company-logo-container {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    height: 84px;

    img {
      max-width: 100%;
      max-height: 56px;
    }

    padding: 14px 7px;
    @include media-breakpoint-up(md) {
      height: 130px;
      padding: 20px 18px;
      img {
        max-height: 80px;
      }
    }
    border: $border-width solid $gray-300;
  }

}

$market-hero-margin-x: 135px !default;
$min-hero-height-mobile: 400px !default;
$form-max-width-desktop: 600px !default;
$max-hero-height-mobile: 568px !default;

.market-hero-image-container {
  position: relative;
  height: 65vh;
  min-height: $min-hero-height-mobile;
  max-height: $max-hero-height-mobile;
  margin-right: -$market-hero-margin-x;
  margin-left: -$market-hero-margin-x;

  background-position: 50% 50%;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .25), rgba(0, 0, 0, 0));
  }

  .market-hero-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 340px;
    margin: auto $market-hero-margin-x;

    .market-hero-trust {
      line-height: 1.25;
    }

    @include media-breakpoint-up(md) {
      display: block;
      height: 223px;
      margin: auto;
    }

    .form-container {
      @include media-breakpoint-up(md) {
        max-width: $form-max-width-desktop;
        margin: auto;
      }

      input,
      select,
      button {
        font-size: 18px;
        line-height: 24px;
        @include media-breakpoint-up(md) {
          width: 50%;
        }
      }

      .description {
        @include media-breakpoint-up(md) {
          margin-left: 50%;
        }
      }
    }

    h1 {
      font-size: 36px;
      line-height: 40px;
      @include media-breakpoint-up(md) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: $font-weight-normal;
      line-height: 24px;
      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}
